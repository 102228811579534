import { useAuth } from "../auth";
import LoginForm from "../components/LoginForm";
import Router from "next/router";

const Login: React.FC<{}> = (): React.ReactElement => {
  const { loading, isAuthenticated, login } = useAuth();
  if (loading) {
    return null;
  } else if (isAuthenticated) {
    Router.push("/inbox");
    return null;
  } else {
    return <LoginForm handleSubmit={login} />;
  }
};

export default Login;
